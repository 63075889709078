.header {
    margin-top: 40px;
    text-align: center;
    
    .full-name {
        text-transform: uppercase;
        font-weight: 700;
    }
    
    .expertise {
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 5px;
    }

    h1, h2 { margin: 0; }
    
    .expertise-lists {
        margin: 15px 0 5px;

        .expertise-skill {
            text-decoration: none;
            margin: 0 5px;
            cursor: pointer;
            
            i {
                font-size:20px;
            }

            &.expertise-node {
                &:hover {
                    text-shadow: 0px 2px 10px #231f21
                }
            }

            &.expertise-php {
                color: #6666cc;
                &:hover {
                    text-shadow: 0px 2px 10px #f7e1c7;
                }
            }

            &.expertise-wp {
                color: #333333;
                &:hover {
                    text-shadow: 0px 2px 10px #f7e1c7;
                }
            }

            &.expertise-react {
                color: #61dafb;
                &:hover {
                    text-shadow: 0px 2px 10px #231f21
                }
            }

            &.expertise-aws {
                color: #000;
                &:hover {
                    text-shadow: 0px 2px 10px #f7e1c7;
                }
            }

        }
    }
}

@media only screen and (max-width: 400px){
    .header {
        .full-name {
            font-size: 28px;
        }

        .expertise {
            font-size: 20px;
        }
    }
}