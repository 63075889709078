.firefly {
    position: relative;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }
  .firefly::before, .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }
  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }
  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }
  
  .firefly:nth-child(1) {
    animation-name: move1;
  }
  .firefly:nth-child(1)::before {
    animation-duration: 12s;
  }
  .firefly:nth-child(1)::after {
    animation-duration: 12s, 10984ms;
    animation-delay: 0ms, 7800ms;
  }
  
  @keyframes move1 {
    0% {
      transform: translateX(28vw) translateY(-18vh) scale(0.31);
    }
    5.8823529412% {
      transform: translateX(17vw) translateY(9vh) scale(0.73);
    }
    11.7647058824% {
      transform: translateX(-36vw) translateY(-47vh) scale(0.26);
    }
    17.6470588235% {
      transform: translateX(31vw) translateY(-9vh) scale(0.33);
    }
    23.5294117647% {
      transform: translateX(17vw) translateY(1vh) scale(0.46);
    }
    29.4117647059% {
      transform: translateX(-5vw) translateY(41vh) scale(0.62);
    }
    35.2941176471% {
      transform: translateX(-45vw) translateY(-29vh) scale(1);
    }
    41.1764705882% {
      transform: translateX(39vw) translateY(31vh) scale(0.96);
    }
    47.0588235294% {
      transform: translateX(-35vw) translateY(33vh) scale(0.36);
    }
    52.9411764706% {
      transform: translateX(39vw) translateY(26vh) scale(0.66);
    }
    58.8235294118% {
      transform: translateX(13vw) translateY(46vh) scale(0.94);
    }
    64.7058823529% {
      transform: translateX(32vw) translateY(-49vh) scale(0.44);
    }
    70.5882352941% {
      transform: translateX(32vw) translateY(-9vh) scale(0.7);
    }
    76.4705882353% {
      transform: translateX(48vw) translateY(6vh) scale(0.86);
    }
    82.3529411765% {
      transform: translateX(5vw) translateY(26vh) scale(0.73);
    }
    88.2352941176% {
      transform: translateX(-18vw) translateY(6vh) scale(0.42);
    }
    94.1176470588% {
      transform: translateX(35vw) translateY(2vh) scale(0.76);
    }
    100% {
      transform: translateX(-18vw) translateY(8vh) scale(0.57);
    }
  }
  .firefly:nth-child(2) {
    animation-name: move2;
  }
  .firefly:nth-child(2)::before {
    animation-duration: 10s;
  }
  .firefly:nth-child(2)::after {
    animation-duration: 10s, 7968ms;
    animation-delay: 0ms, 5069ms;
  }
  
  @keyframes move2 {
    0% {
      transform: translateX(11vw) translateY(25vh) scale(0.33);
    }
    4% {
      transform: translateX(21vw) translateY(-31vh) scale(0.41);
    }
    8% {
      transform: translateX(16vw) translateY(33vh) scale(0.88);
    }
    12% {
      transform: translateX(47vw) translateY(49vh) scale(0.44);
    }
    16% {
      transform: translateX(-35vw) translateY(-7vh) scale(0.49);
    }
    20% {
      transform: translateX(-23vw) translateY(17vh) scale(0.82);
    }
    24% {
      transform: translateX(-14vw) translateY(-29vh) scale(0.64);
    }
    28% {
      transform: translateX(15vw) translateY(42vh) scale(0.88);
    }
    32% {
      transform: translateX(-7vw) translateY(23vh) scale(0.62);
    }
    36% {
      transform: translateX(-34vw) translateY(-42vh) scale(0.34);
    }
    40% {
      transform: translateX(-26vw) translateY(-19vh) scale(0.83);
    }
    44% {
      transform: translateX(6vw) translateY(-30vh) scale(0.27);
    }
    48% {
      transform: translateX(10vw) translateY(22vh) scale(0.47);
    }
    52% {
      transform: translateX(34vw) translateY(-47vh) scale(0.45);
    }
    56% {
      transform: translateX(-28vw) translateY(13vh) scale(0.96);
    }
    60% {
      transform: translateX(-40vw) translateY(-1vh) scale(0.58);
    }
    64% {
      transform: translateX(-6vw) translateY(34vh) scale(0.71);
    }
    68% {
      transform: translateX(21vw) translateY(-25vh) scale(0.67);
    }
    72% {
      transform: translateX(-20vw) translateY(11vh) scale(0.34);
    }
    76% {
      transform: translateX(28vw) translateY(5vh) scale(0.92);
    }
    80% {
      transform: translateX(-35vw) translateY(9vh) scale(0.74);
    }
    84% {
      transform: translateX(28vw) translateY(8vh) scale(0.67);
    }
    88% {
      transform: translateX(-30vw) translateY(19vh) scale(0.57);
    }
    92% {
      transform: translateX(38vw) translateY(49vh) scale(0.79);
    }
    96% {
      transform: translateX(35vw) translateY(45vh) scale(0.72);
    }
    100% {
      transform: translateX(7vw) translateY(-10vh) scale(0.46);
    }
  }
  .firefly:nth-child(3) {
    animation-name: move3;
  }
  .firefly:nth-child(3)::before {
    animation-duration: 15s;
  }
  .firefly:nth-child(3)::after {
    animation-duration: 15s, 6172ms;
    animation-delay: 0ms, 5406ms;
  }
  
  @keyframes move3 {
    0% {
      transform: translateX(37vw) translateY(-49vh) scale(0.5);
    }
    4.347826087% {
      transform: translateX(15vw) translateY(-2vh) scale(0.46);
    }
    8.6956521739% {
      transform: translateX(-42vw) translateY(-47vh) scale(0.7);
    }
    13.0434782609% {
      transform: translateX(0vw) translateY(-17vh) scale(0.42);
    }
    17.3913043478% {
      transform: translateX(-38vw) translateY(-13vh) scale(0.97);
    }
    21.7391304348% {
      transform: translateX(-25vw) translateY(18vh) scale(0.87);
    }
    26.0869565217% {
      transform: translateX(1vw) translateY(-13vh) scale(0.52);
    }
    30.4347826087% {
      transform: translateX(25vw) translateY(46vh) scale(0.39);
    }
    34.7826086957% {
      transform: translateX(-7vw) translateY(-29vh) scale(0.95);
    }
    39.1304347826% {
      transform: translateX(48vw) translateY(-41vh) scale(0.42);
    }
    43.4782608696% {
      transform: translateX(4vw) translateY(40vh) scale(0.28);
    }
    47.8260869565% {
      transform: translateX(-5vw) translateY(17vh) scale(0.86);
    }
    52.1739130435% {
      transform: translateX(18vw) translateY(1vh) scale(0.42);
    }
    56.5217391304% {
      transform: translateX(24vw) translateY(-35vh) scale(0.77);
    }
    60.8695652174% {
      transform: translateX(19vw) translateY(48vh) scale(0.44);
    }
    65.2173913043% {
      transform: translateX(6vw) translateY(18vh) scale(0.74);
    }
    69.5652173913% {
      transform: translateX(14vw) translateY(38vh) scale(0.49);
    }
    73.9130434783% {
      transform: translateX(-37vw) translateY(-44vh) scale(0.52);
    }
    78.2608695652% {
      transform: translateX(47vw) translateY(-1vh) scale(0.31);
    }
    82.6086956522% {
      transform: translateX(-41vw) translateY(-16vh) scale(0.65);
    }
    86.9565217391% {
      transform: translateX(3vw) translateY(-25vh) scale(0.49);
    }
    91.3043478261% {
      transform: translateX(9vw) translateY(50vh) scale(0.82);
    }
    95.652173913% {
      transform: translateX(-39vw) translateY(25vh) scale(0.6);
    }
    100% {
      transform: translateX(-29vw) translateY(25vh) scale(0.97);
    }
  }
  .firefly:nth-child(4) {
    animation-name: move4;
  }
  .firefly:nth-child(4)::before {
    animation-duration: 14s;
  }
  .firefly:nth-child(4)::after {
    animation-duration: 14s, 9072ms;
    animation-delay: 0ms, 7625ms;
  }
  
  @keyframes move4 {
    0% {
      transform: translateX(-5vw) translateY(27vh) scale(0.63);
    }
    5% {
      transform: translateX(35vw) translateY(-29vh) scale(0.59);
    }
    10% {
      transform: translateX(-35vw) translateY(36vh) scale(0.87);
    }
    15% {
      transform: translateX(-47vw) translateY(-34vh) scale(0.92);
    }
    20% {
      transform: translateX(2vw) translateY(3vh) scale(1);
    }
    25% {
      transform: translateX(-2vw) translateY(-18vh) scale(0.6);
    }
    30% {
      transform: translateX(5vw) translateY(-36vh) scale(0.82);
    }
    35% {
      transform: translateX(-36vw) translateY(15vh) scale(0.83);
    }
    40% {
      transform: translateX(-46vw) translateY(45vh) scale(0.31);
    }
    45% {
      transform: translateX(-3vw) translateY(-32vh) scale(0.45);
    }
    50% {
      transform: translateX(48vw) translateY(-30vh) scale(0.27);
    }
    55% {
      transform: translateX(24vw) translateY(-14vh) scale(0.76);
    }
    60% {
      transform: translateX(33vw) translateY(-12vh) scale(0.38);
    }
    65% {
      transform: translateX(24vw) translateY(-7vh) scale(0.82);
    }
    70% {
      transform: translateX(-15vw) translateY(9vh) scale(0.37);
    }
    75% {
      transform: translateX(50vw) translateY(-24vh) scale(0.68);
    }
    80% {
      transform: translateX(38vw) translateY(28vh) scale(0.29);
    }
    85% {
      transform: translateX(33vw) translateY(46vh) scale(0.83);
    }
    90% {
      transform: translateX(-36vw) translateY(38vh) scale(0.47);
    }
    95% {
      transform: translateX(-20vw) translateY(-4vh) scale(0.8);
    }
    100% {
      transform: translateX(-31vw) translateY(49vh) scale(0.87);
    }
  }
  .firefly:nth-child(5) {
    animation-name: move5;
  }
  .firefly:nth-child(5)::before {
    animation-duration: 12s;
  }
  .firefly:nth-child(5)::after {
    animation-duration: 12s, 9688ms;
    animation-delay: 0ms, 3236ms;
  }
  
  @keyframes move5 {
    0% {
      transform: translateX(13vw) translateY(-10vh) scale(0.66);
    }
    3.8461538462% {
      transform: translateX(20vw) translateY(35vh) scale(0.64);
    }
    7.6923076923% {
      transform: translateX(-19vw) translateY(11vh) scale(0.82);
    }
    11.5384615385% {
      transform: translateX(-40vw) translateY(-41vh) scale(0.47);
    }
    15.3846153846% {
      transform: translateX(36vw) translateY(42vh) scale(0.62);
    }
    19.2307692308% {
      transform: translateX(44vw) translateY(-43vh) scale(0.68);
    }
    23.0769230769% {
      transform: translateX(-28vw) translateY(47vh) scale(0.91);
    }
    26.9230769231% {
      transform: translateX(3vw) translateY(-20vh) scale(0.41);
    }
    30.7692307692% {
      transform: translateX(36vw) translateY(-34vh) scale(0.59);
    }
    34.6153846154% {
      transform: translateX(-31vw) translateY(-1vh) scale(0.84);
    }
    38.4615384615% {
      transform: translateX(-42vw) translateY(-38vh) scale(0.43);
    }
    42.3076923077% {
      transform: translateX(-41vw) translateY(4vh) scale(0.68);
    }
    46.1538461538% {
      transform: translateX(0vw) translateY(-17vh) scale(0.92);
    }
    50% {
      transform: translateX(19vw) translateY(2vh) scale(0.68);
    }
    53.8461538462% {
      transform: translateX(42vw) translateY(-15vh) scale(0.79);
    }
    57.6923076923% {
      transform: translateX(-11vw) translateY(-31vh) scale(0.36);
    }
    61.5384615385% {
      transform: translateX(25vw) translateY(20vh) scale(0.29);
    }
    65.3846153846% {
      transform: translateX(-9vw) translateY(22vh) scale(0.42);
    }
    69.2307692308% {
      transform: translateX(4vw) translateY(47vh) scale(0.87);
    }
    73.0769230769% {
      transform: translateX(21vw) translateY(-17vh) scale(0.57);
    }
    76.9230769231% {
      transform: translateX(-47vw) translateY(2vh) scale(0.73);
    }
    80.7692307692% {
      transform: translateX(-21vw) translateY(-33vh) scale(0.33);
    }
    84.6153846154% {
      transform: translateX(4vw) translateY(-32vh) scale(0.61);
    }
    88.4615384615% {
      transform: translateX(-48vw) translateY(-8vh) scale(0.33);
    }
    92.3076923077% {
      transform: translateX(9vw) translateY(-24vh) scale(0.61);
    }
    96.1538461538% {
      transform: translateX(12vw) translateY(-25vh) scale(0.72);
    }
    100% {
      transform: translateX(20vw) translateY(-14vh) scale(0.56);
    }
  }
  .firefly:nth-child(6) {
    animation-name: move6;
  }
  .firefly:nth-child(6)::before {
    animation-duration: 12s;
  }
  .firefly:nth-child(6)::after {
    animation-duration: 12s, 7841ms;
    animation-delay: 0ms, 1833ms;
  }
  
  @keyframes move6 {
    0% {
      transform: translateX(6vw) translateY(42vh) scale(0.57);
    }
    4.347826087% {
      transform: translateX(3vw) translateY(-49vh) scale(0.99);
    }
    8.6956521739% {
      transform: translateX(45vw) translateY(-45vh) scale(0.62);
    }
    13.0434782609% {
      transform: translateX(-8vw) translateY(-2vh) scale(0.93);
    }
    17.3913043478% {
      transform: translateX(-37vw) translateY(24vh) scale(0.28);
    }
    21.7391304348% {
      transform: translateX(-49vw) translateY(-41vh) scale(0.77);
    }
    26.0869565217% {
      transform: translateX(-1vw) translateY(-24vh) scale(0.67);
    }
    30.4347826087% {
      transform: translateX(5vw) translateY(-6vh) scale(0.48);
    }
    34.7826086957% {
      transform: translateX(-23vw) translateY(-2vh) scale(0.59);
    }
    39.1304347826% {
      transform: translateX(-46vw) translateY(47vh) scale(0.8);
    }
    43.4782608696% {
      transform: translateX(5vw) translateY(19vh) scale(0.4);
    }
    47.8260869565% {
      transform: translateX(32vw) translateY(-11vh) scale(0.26);
    }
    52.1739130435% {
      transform: translateX(50vw) translateY(25vh) scale(0.47);
    }
    56.5217391304% {
      transform: translateX(18vw) translateY(28vh) scale(0.71);
    }
    60.8695652174% {
      transform: translateX(-29vw) translateY(17vh) scale(0.46);
    }
    65.2173913043% {
      transform: translateX(-5vw) translateY(-43vh) scale(0.95);
    }
    69.5652173913% {
      transform: translateX(34vw) translateY(-11vh) scale(0.58);
    }
    73.9130434783% {
      transform: translateX(-4vw) translateY(-42vh) scale(0.43);
    }
    78.2608695652% {
      transform: translateX(-33vw) translateY(-4vh) scale(0.63);
    }
    82.6086956522% {
      transform: translateX(-3vw) translateY(28vh) scale(0.81);
    }
    86.9565217391% {
      transform: translateX(-39vw) translateY(43vh) scale(0.31);
    }
    91.3043478261% {
      transform: translateX(18vw) translateY(-21vh) scale(0.44);
    }
    95.652173913% {
      transform: translateX(36vw) translateY(32vh) scale(0.49);
    }
    100% {
      transform: translateX(44vw) translateY(21vh) scale(0.34);
    }
  }
  .firefly:nth-child(7) {
    animation-name: move7;
  }
  .firefly:nth-child(7)::before {
    animation-duration: 16s;
  }
  .firefly:nth-child(7)::after {
    animation-duration: 16s, 9437ms;
    animation-delay: 0ms, 4125ms;
  }
  
  @keyframes move7 {
    0% {
      transform: translateX(-19vw) translateY(35vh) scale(0.98);
    }
    3.8461538462% {
      transform: translateX(-23vw) translateY(-19vh) scale(0.27);
    }
    7.6923076923% {
      transform: translateX(-27vw) translateY(0vh) scale(0.43);
    }
    11.5384615385% {
      transform: translateX(-4vw) translateY(25vh) scale(0.35);
    }
    15.3846153846% {
      transform: translateX(27vw) translateY(30vh) scale(0.98);
    }
    19.2307692308% {
      transform: translateX(-24vw) translateY(38vh) scale(0.9);
    }
    23.0769230769% {
      transform: translateX(-44vw) translateY(32vh) scale(0.34);
    }
    26.9230769231% {
      transform: translateX(-3vw) translateY(-43vh) scale(0.98);
    }
    30.7692307692% {
      transform: translateX(-44vw) translateY(-14vh) scale(1);
    }
    34.6153846154% {
      transform: translateX(-33vw) translateY(-15vh) scale(0.63);
    }
    38.4615384615% {
      transform: translateX(33vw) translateY(-15vh) scale(0.95);
    }
    42.3076923077% {
      transform: translateX(-42vw) translateY(-31vh) scale(0.76);
    }
    46.1538461538% {
      transform: translateX(0vw) translateY(-33vh) scale(0.43);
    }
    50% {
      transform: translateX(33vw) translateY(44vh) scale(0.4);
    }
    53.8461538462% {
      transform: translateX(35vw) translateY(18vh) scale(0.45);
    }
    57.6923076923% {
      transform: translateX(-3vw) translateY(22vh) scale(0.4);
    }
    61.5384615385% {
      transform: translateX(32vw) translateY(-42vh) scale(0.4);
    }
    65.3846153846% {
      transform: translateX(23vw) translateY(-45vh) scale(0.35);
    }
    69.2307692308% {
      transform: translateX(-42vw) translateY(-29vh) scale(0.76);
    }
    73.0769230769% {
      transform: translateX(-39vw) translateY(-12vh) scale(1);
    }
    76.9230769231% {
      transform: translateX(48vw) translateY(-32vh) scale(0.98);
    }
    80.7692307692% {
      transform: translateX(-36vw) translateY(-22vh) scale(0.65);
    }
    84.6153846154% {
      transform: translateX(28vw) translateY(41vh) scale(0.82);
    }
    88.4615384615% {
      transform: translateX(49vw) translateY(4vh) scale(0.97);
    }
    92.3076923077% {
      transform: translateX(50vw) translateY(-42vh) scale(0.52);
    }
    96.1538461538% {
      transform: translateX(-48vw) translateY(50vh) scale(0.39);
    }
    100% {
      transform: translateX(49vw) translateY(-26vh) scale(0.58);
    }
  }
  .firefly:nth-child(8) {
    animation-name: move8;
  }
  .firefly:nth-child(8)::before {
    animation-duration: 17s;
  }
  .firefly:nth-child(8)::after {
    animation-duration: 17s, 7510ms;
    animation-delay: 0ms, 5234ms;
  }
  
  @keyframes move8 {
    0% {
      transform: translateX(43vw) translateY(40vh) scale(0.95);
    }
    5.5555555556% {
      transform: translateX(-18vw) translateY(40vh) scale(0.41);
    }
    11.1111111111% {
      transform: translateX(-18vw) translateY(17vh) scale(0.31);
    }
    16.6666666667% {
      transform: translateX(33vw) translateY(28vh) scale(0.35);
    }
    22.2222222222% {
      transform: translateX(-3vw) translateY(0vh) scale(0.27);
    }
    27.7777777778% {
      transform: translateX(-49vw) translateY(-26vh) scale(0.43);
    }
    33.3333333333% {
      transform: translateX(-6vw) translateY(-43vh) scale(0.55);
    }
    38.8888888889% {
      transform: translateX(42vw) translateY(-16vh) scale(0.52);
    }
    44.4444444444% {
      transform: translateX(-36vw) translateY(3vh) scale(0.97);
    }
    50% {
      transform: translateX(-20vw) translateY(28vh) scale(0.98);
    }
    55.5555555556% {
      transform: translateX(50vw) translateY(-33vh) scale(0.68);
    }
    61.1111111111% {
      transform: translateX(27vw) translateY(32vh) scale(0.36);
    }
    66.6666666667% {
      transform: translateX(-39vw) translateY(14vh) scale(0.36);
    }
    72.2222222222% {
      transform: translateX(40vw) translateY(-22vh) scale(0.76);
    }
    77.7777777778% {
      transform: translateX(21vw) translateY(22vh) scale(0.68);
    }
    83.3333333333% {
      transform: translateX(-27vw) translateY(3vh) scale(0.52);
    }
    88.8888888889% {
      transform: translateX(-12vw) translateY(12vh) scale(0.52);
    }
    94.4444444444% {
      transform: translateX(-27vw) translateY(-49vh) scale(0.95);
    }
    100% {
      transform: translateX(42vw) translateY(2vh) scale(0.68);
    }
  }
  .firefly:nth-child(9) {
    animation-name: move9;
  }
  .firefly:nth-child(9)::before {
    animation-duration: 14s;
  }
  .firefly:nth-child(9)::after {
    animation-duration: 14s, 7766ms;
    animation-delay: 0ms, 8269ms;
  }
  
  @keyframes move9 {
    0% {
      transform: translateX(-31vw) translateY(-6vh) scale(0.73);
    }
    4.1666666667% {
      transform: translateX(-13vw) translateY(-42vh) scale(0.65);
    }
    8.3333333333% {
      transform: translateX(4vw) translateY(11vh) scale(0.61);
    }
    12.5% {
      transform: translateX(-38vw) translateY(34vh) scale(0.97);
    }
    16.6666666667% {
      transform: translateX(34vw) translateY(-34vh) scale(0.88);
    }
    20.8333333333% {
      transform: translateX(31vw) translateY(30vh) scale(0.28);
    }
    25% {
      transform: translateX(-38vw) translateY(45vh) scale(0.69);
    }
    29.1666666667% {
      transform: translateX(-37vw) translateY(-21vh) scale(0.99);
    }
    33.3333333333% {
      transform: translateX(-12vw) translateY(17vh) scale(0.9);
    }
    37.5% {
      transform: translateX(-13vw) translateY(-22vh) scale(0.68);
    }
    41.6666666667% {
      transform: translateX(18vw) translateY(5vh) scale(0.91);
    }
    45.8333333333% {
      transform: translateX(-7vw) translateY(-28vh) scale(0.88);
    }
    50% {
      transform: translateX(-31vw) translateY(-31vh) scale(0.54);
    }
    54.1666666667% {
      transform: translateX(-9vw) translateY(46vh) scale(0.87);
    }
    58.3333333333% {
      transform: translateX(38vw) translateY(-29vh) scale(0.57);
    }
    62.5% {
      transform: translateX(31vw) translateY(26vh) scale(0.77);
    }
    66.6666666667% {
      transform: translateX(-1vw) translateY(-31vh) scale(0.45);
    }
    70.8333333333% {
      transform: translateX(-33vw) translateY(-22vh) scale(0.52);
    }
    75% {
      transform: translateX(-27vw) translateY(-32vh) scale(0.97);
    }
    79.1666666667% {
      transform: translateX(11vw) translateY(-11vh) scale(0.8);
    }
    83.3333333333% {
      transform: translateX(-17vw) translateY(-37vh) scale(0.69);
    }
    87.5% {
      transform: translateX(29vw) translateY(37vh) scale(0.6);
    }
    91.6666666667% {
      transform: translateX(18vw) translateY(-24vh) scale(0.28);
    }
    95.8333333333% {
      transform: translateX(-38vw) translateY(18vh) scale(0.68);
    }
    100% {
      transform: translateX(-15vw) translateY(40vh) scale(0.45);
    }
  }
  .firefly:nth-child(10) {
    animation-name: move10;
  }
  .firefly:nth-child(10)::before {
    animation-duration: 14s;
  }
  .firefly:nth-child(10)::after {
    animation-duration: 14s, 6485ms;
    animation-delay: 0ms, 5845ms;
  }
  
  @keyframes move10 {
    0% {
      transform: translateX(-2vw) translateY(28vh) scale(0.85);
    }
    3.7037037037% {
      transform: translateX(35vw) translateY(-15vh) scale(0.89);
    }
    7.4074074074% {
      transform: translateX(11vw) translateY(44vh) scale(0.42);
    }
    11.1111111111% {
      transform: translateX(37vw) translateY(-30vh) scale(0.69);
    }
    14.8148148148% {
      transform: translateX(5vw) translateY(38vh) scale(0.61);
    }
    18.5185185185% {
      transform: translateX(9vw) translateY(9vh) scale(0.71);
    }
    22.2222222222% {
      transform: translateX(41vw) translateY(-10vh) scale(0.69);
    }
    25.9259259259% {
      transform: translateX(2vw) translateY(-11vh) scale(0.68);
    }
    29.6296296296% {
      transform: translateX(17vw) translateY(49vh) scale(0.4);
    }
    33.3333333333% {
      transform: translateX(-39vw) translateY(41vh) scale(0.84);
    }
    37.037037037% {
      transform: translateX(16vw) translateY(-15vh) scale(0.29);
    }
    40.7407407407% {
      transform: translateX(-13vw) translateY(-9vh) scale(0.92);
    }
    44.4444444444% {
      transform: translateX(-38vw) translateY(12vh) scale(0.57);
    }
    48.1481481481% {
      transform: translateX(28vw) translateY(40vh) scale(0.87);
    }
    51.8518518519% {
      transform: translateX(-41vw) translateY(30vh) scale(0.67);
    }
    55.5555555556% {
      transform: translateX(19vw) translateY(-27vh) scale(0.43);
    }
    59.2592592593% {
      transform: translateX(-9vw) translateY(-16vh) scale(0.92);
    }
    62.962962963% {
      transform: translateX(-26vw) translateY(21vh) scale(0.65);
    }
    66.6666666667% {
      transform: translateX(31vw) translateY(-14vh) scale(0.88);
    }
    70.3703703704% {
      transform: translateX(33vw) translateY(-48vh) scale(0.34);
    }
    74.0740740741% {
      transform: translateX(7vw) translateY(-33vh) scale(0.72);
    }
    77.7777777778% {
      transform: translateX(31vw) translateY(11vh) scale(0.69);
    }
    81.4814814815% {
      transform: translateX(-21vw) translateY(16vh) scale(0.42);
    }
    85.1851851852% {
      transform: translateX(-13vw) translateY(-27vh) scale(0.99);
    }
    88.8888888889% {
      transform: translateX(-11vw) translateY(47vh) scale(0.94);
    }
    92.5925925926% {
      transform: translateX(3vw) translateY(18vh) scale(0.46);
    }
    96.2962962963% {
      transform: translateX(-46vw) translateY(-30vh) scale(0.44);
    }
    100% {
      transform: translateX(12vw) translateY(-13vh) scale(0.5);
    }
  }
  .firefly:nth-child(11) {
    animation-name: move11;
  }
  .firefly:nth-child(11)::before {
    animation-duration: 15s;
  }
  .firefly:nth-child(11)::after {
    animation-duration: 15s, 9233ms;
    animation-delay: 0ms, 2587ms;
  }
  
  @keyframes move11 {
    0% {
      transform: translateX(-43vw) translateY(10vh) scale(0.46);
    }
    3.8461538462% {
      transform: translateX(20vw) translateY(41vh) scale(0.99);
    }
    7.6923076923% {
      transform: translateX(10vw) translateY(29vh) scale(0.6);
    }
    11.5384615385% {
      transform: translateX(39vw) translateY(46vh) scale(0.68);
    }
    15.3846153846% {
      transform: translateX(-21vw) translateY(8vh) scale(0.37);
    }
    19.2307692308% {
      transform: translateX(-47vw) translateY(-6vh) scale(0.57);
    }
    23.0769230769% {
      transform: translateX(-38vw) translateY(-20vh) scale(0.87);
    }
    26.9230769231% {
      transform: translateX(-1vw) translateY(2vh) scale(0.59);
    }
    30.7692307692% {
      transform: translateX(-13vw) translateY(-32vh) scale(0.51);
    }
    34.6153846154% {
      transform: translateX(-26vw) translateY(16vh) scale(0.49);
    }
    38.4615384615% {
      transform: translateX(-35vw) translateY(-22vh) scale(0.49);
    }
    42.3076923077% {
      transform: translateX(4vw) translateY(21vh) scale(0.98);
    }
    46.1538461538% {
      transform: translateX(-24vw) translateY(7vh) scale(0.7);
    }
    50% {
      transform: translateX(-23vw) translateY(-7vh) scale(0.82);
    }
    53.8461538462% {
      transform: translateX(31vw) translateY(-17vh) scale(0.35);
    }
    57.6923076923% {
      transform: translateX(33vw) translateY(23vh) scale(0.35);
    }
    61.5384615385% {
      transform: translateX(-20vw) translateY(2vh) scale(0.34);
    }
    65.3846153846% {
      transform: translateX(0vw) translateY(44vh) scale(0.88);
    }
    69.2307692308% {
      transform: translateX(-9vw) translateY(-6vh) scale(0.33);
    }
    73.0769230769% {
      transform: translateX(-16vw) translateY(29vh) scale(0.33);
    }
    76.9230769231% {
      transform: translateX(-47vw) translateY(-38vh) scale(0.79);
    }
    80.7692307692% {
      transform: translateX(44vw) translateY(-43vh) scale(0.83);
    }
    84.6153846154% {
      transform: translateX(-4vw) translateY(-44vh) scale(0.44);
    }
    88.4615384615% {
      transform: translateX(-20vw) translateY(27vh) scale(0.95);
    }
    92.3076923077% {
      transform: translateX(8vw) translateY(-46vh) scale(0.87);
    }
    96.1538461538% {
      transform: translateX(35vw) translateY(1vh) scale(0.77);
    }
    100% {
      transform: translateX(-5vw) translateY(-17vh) scale(0.28);
    }
  }
  .firefly:nth-child(12) {
    animation-name: move12;
  }
  .firefly:nth-child(12)::before {
    animation-duration: 16s;
  }
  .firefly:nth-child(12)::after {
    animation-duration: 16s, 8669ms;
    animation-delay: 0ms, 7724ms;
  }
  
  @keyframes move12 {
    0% {
      transform: translateX(-43vw) translateY(-47vh) scale(0.48);
    }
    5% {
      transform: translateX(32vw) translateY(2vh) scale(0.56);
    }
    10% {
      transform: translateX(48vw) translateY(-26vh) scale(0.35);
    }
    15% {
      transform: translateX(-13vw) translateY(48vh) scale(0.55);
    }
    20% {
      transform: translateX(-38vw) translateY(0vh) scale(0.7);
    }
    25% {
      transform: translateX(13vw) translateY(49vh) scale(0.54);
    }
    30% {
      transform: translateX(46vw) translateY(29vh) scale(0.7);
    }
    35% {
      transform: translateX(39vw) translateY(23vh) scale(0.72);
    }
    40% {
      transform: translateX(33vw) translateY(4vh) scale(0.5);
    }
    45% {
      transform: translateX(44vw) translateY(-10vh) scale(0.99);
    }
    50% {
      transform: translateX(-36vw) translateY(-11vh) scale(0.93);
    }
    55% {
      transform: translateX(-16vw) translateY(8vh) scale(1);
    }
    60% {
      transform: translateX(-23vw) translateY(-38vh) scale(0.84);
    }
    65% {
      transform: translateX(-32vw) translateY(-38vh) scale(0.91);
    }
    70% {
      transform: translateX(42vw) translateY(-49vh) scale(0.9);
    }
    75% {
      transform: translateX(17vw) translateY(-5vh) scale(0.47);
    }
    80% {
      transform: translateX(-43vw) translateY(-40vh) scale(0.96);
    }
    85% {
      transform: translateX(13vw) translateY(-29vh) scale(0.54);
    }
    90% {
      transform: translateX(16vw) translateY(50vh) scale(0.8);
    }
    95% {
      transform: translateX(-47vw) translateY(-7vh) scale(0.52);
    }
    100% {
      transform: translateX(-20vw) translateY(1vh) scale(0.39);
    }
  }
  .firefly:nth-child(13) {
    animation-name: move13;
  }
  .firefly:nth-child(13)::before {
    animation-duration: 17s;
  }
  .firefly:nth-child(13)::after {
    animation-duration: 17s, 9521ms;
    animation-delay: 0ms, 4768ms;
  }
  
  @keyframes move13 {
    0% {
      transform: translateX(-37vw) translateY(-27vh) scale(0.79);
    }
    5% {
      transform: translateX(25vw) translateY(-4vh) scale(0.64);
    }
    10% {
      transform: translateX(12vw) translateY(-4vh) scale(0.55);
    }
    15% {
      transform: translateX(40vw) translateY(27vh) scale(0.87);
    }
    20% {
      transform: translateX(-2vw) translateY(1vh) scale(0.33);
    }
    25% {
      transform: translateX(-26vw) translateY(39vh) scale(0.89);
    }
    30% {
      transform: translateX(10vw) translateY(33vh) scale(0.27);
    }
    35% {
      transform: translateX(-40vw) translateY(24vh) scale(0.86);
    }
    40% {
      transform: translateX(-27vw) translateY(45vh) scale(0.57);
    }
    45% {
      transform: translateX(2vw) translateY(50vh) scale(0.74);
    }
    50% {
      transform: translateX(21vw) translateY(30vh) scale(0.99);
    }
    55% {
      transform: translateX(-17vw) translateY(-7vh) scale(0.91);
    }
    60% {
      transform: translateX(-45vw) translateY(-12vh) scale(0.43);
    }
    65% {
      transform: translateX(12vw) translateY(45vh) scale(0.64);
    }
    70% {
      transform: translateX(16vw) translateY(19vh) scale(0.76);
    }
    75% {
      transform: translateX(-26vw) translateY(-8vh) scale(0.35);
    }
    80% {
      transform: translateX(20vw) translateY(30vh) scale(0.95);
    }
    85% {
      transform: translateX(-48vw) translateY(-8vh) scale(0.9);
    }
    90% {
      transform: translateX(-4vw) translateY(-27vh) scale(0.27);
    }
    95% {
      transform: translateX(-30vw) translateY(45vh) scale(0.41);
    }
    100% {
      transform: translateX(27vw) translateY(-27vh) scale(0.77);
    }
  }
  .firefly:nth-child(14) {
    animation-name: move14;
  }
  .firefly:nth-child(14)::before {
    animation-duration: 11s;
  }
  .firefly:nth-child(14)::after {
    animation-duration: 11s, 10124ms;
    animation-delay: 0ms, 6811ms;
  }
  
  @keyframes move14 {
    0% {
      transform: translateX(11vw) translateY(-18vh) scale(0.39);
    }
    4.5454545455% {
      transform: translateX(-16vw) translateY(13vh) scale(0.26);
    }
    9.0909090909% {
      transform: translateX(-15vw) translateY(-40vh) scale(0.58);
    }
    13.6363636364% {
      transform: translateX(-21vw) translateY(42vh) scale(0.51);
    }
    18.1818181818% {
      transform: translateX(-7vw) translateY(-39vh) scale(1);
    }
    22.7272727273% {
      transform: translateX(0vw) translateY(19vh) scale(0.64);
    }
    27.2727272727% {
      transform: translateX(49vw) translateY(-22vh) scale(0.91);
    }
    31.8181818182% {
      transform: translateX(-13vw) translateY(-1vh) scale(0.91);
    }
    36.3636363636% {
      transform: translateX(14vw) translateY(-25vh) scale(0.65);
    }
    40.9090909091% {
      transform: translateX(18vw) translateY(-14vh) scale(0.45);
    }
    45.4545454545% {
      transform: translateX(-45vw) translateY(23vh) scale(0.73);
    }
    50% {
      transform: translateX(-24vw) translateY(-4vh) scale(0.31);
    }
    54.5454545455% {
      transform: translateX(-24vw) translateY(10vh) scale(0.95);
    }
    59.0909090909% {
      transform: translateX(27vw) translateY(-19vh) scale(0.77);
    }
    63.6363636364% {
      transform: translateX(-48vw) translateY(-26vh) scale(0.87);
    }
    68.1818181818% {
      transform: translateX(39vw) translateY(19vh) scale(0.35);
    }
    72.7272727273% {
      transform: translateX(-20vw) translateY(16vh) scale(0.38);
    }
    77.2727272727% {
      transform: translateX(-28vw) translateY(-27vh) scale(0.35);
    }
    81.8181818182% {
      transform: translateX(-4vw) translateY(22vh) scale(0.71);
    }
    86.3636363636% {
      transform: translateX(-8vw) translateY(15vh) scale(0.58);
    }
    90.9090909091% {
      transform: translateX(11vw) translateY(40vh) scale(0.79);
    }
    95.4545454545% {
      transform: translateX(-17vw) translateY(41vh) scale(0.94);
    }
    100% {
      transform: translateX(28vw) translateY(14vh) scale(0.43);
    }
  }
  .firefly:nth-child(15) {
    animation-name: move15;
  }
  .firefly:nth-child(15)::before {
    animation-duration: 18s;
  }
  .firefly:nth-child(15)::after {
    animation-duration: 18s, 7345ms;
    animation-delay: 0ms, 3628ms;
  }
  
  @keyframes move15 {
    0% {
      transform: translateX(-31vw) translateY(-19vh) scale(0.63);
    }
    4.5454545455% {
      transform: translateX(-36vw) translateY(-38vh) scale(0.26);
    }
    9.0909090909% {
      transform: translateX(-21vw) translateY(-18vh) scale(0.93);
    }
    13.6363636364% {
      transform: translateX(3vw) translateY(-41vh) scale(0.51);
    }
    18.1818181818% {
      transform: translateX(-12vw) translateY(34vh) scale(0.6);
    }
    22.7272727273% {
      transform: translateX(-27vw) translateY(14vh) scale(0.89);
    }
    27.2727272727% {
      transform: translateX(50vw) translateY(44vh) scale(0.89);
    }
    31.8181818182% {
      transform: translateX(-28vw) translateY(29vh) scale(0.73);
    }
    36.3636363636% {
      transform: translateX(-16vw) translateY(49vh) scale(0.6);
    }
    40.9090909091% {
      transform: translateX(-11vw) translateY(44vh) scale(0.3);
    }
    45.4545454545% {
      transform: translateX(-7vw) translateY(25vh) scale(0.77);
    }
    50% {
      transform: translateX(45vw) translateY(-5vh) scale(0.75);
    }
    54.5454545455% {
      transform: translateX(-28vw) translateY(43vh) scale(0.32);
    }
    59.0909090909% {
      transform: translateX(-28vw) translateY(13vh) scale(0.99);
    }
    63.6363636364% {
      transform: translateX(-46vw) translateY(44vh) scale(0.29);
    }
    68.1818181818% {
      transform: translateX(25vw) translateY(-42vh) scale(0.68);
    }
    72.7272727273% {
      transform: translateX(33vw) translateY(-38vh) scale(0.74);
    }
    77.2727272727% {
      transform: translateX(47vw) translateY(-20vh) scale(0.66);
    }
    81.8181818182% {
      transform: translateX(11vw) translateY(-28vh) scale(0.58);
    }
    86.3636363636% {
      transform: translateX(-24vw) translateY(7vh) scale(0.81);
    }
    90.9090909091% {
      transform: translateX(-39vw) translateY(-17vh) scale(0.42);
    }
    95.4545454545% {
      transform: translateX(0vw) translateY(-20vh) scale(0.98);
    }
    100% {
      transform: translateX(-37vw) translateY(-21vh) scale(0.74);
    }
  }
  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes flash {
    0%, 30%, 100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
    }
    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }