.footer{
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.4);

    .social-contacts {
        padding: 10px;

        .social {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            margin: 5px 0;
            border-radius: 5px;
            padding:2px;
            color: #fff;
            font-size: 12px;
            
            i {
                margin-right: 5px;
            }

            &.social-linkedin i{
                color: #0077B5;
            }

            &.social-github i{
                color: #c9510c;
            }

            &.social-wordpress i{
                color: #464646;
            }

            &.social-twitter i{
                color: #1da1f2;
            }
        }
    }

    .qr-code-area {
        padding: 5px;
        .qr-code {
            display: block;
            width: 130px;
        }
    }
}