.fade-in {
    -webkit-animation: fade-in 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: fade-in 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}